<template>
  <div>
    <master-component
      :config="config"
      :showRemove="false"
      :showAdd="false"
    >
      <template v-slot:table-custom-event-button="{ item }">
        <custom-tooltip
          v-slot="{ on }"
          title="Team"
        >
          <v-icon
            v-show="item.teamId"
            v-on="on"
            @click="teamDetails(item, index)"
            class="ml-2"
          >mdi-account-multiple</v-icon>
        </custom-tooltip>
      </template>

    </master-component>
  </div>
</template>


<script>
import { getLocations } from "../js/dropdown-service";
import { UserRole, InputType, DialogSize, Usr } from "../js/constants";
import { getToken } from "../js/authorization";

export default {
  data() {
    return {
      config: {},
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "master-member",
        shortName: "สมาชิก",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
    teamDetails(item, index) {
      let routeData = this.$router.resolve({
        name: "team-details",
        params: {
          teamId: item.teamId,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  computed: {
    urlSection() {
      let searchUrl = "/member/search";
      let getUrl = "/member";
      let addUrl = "/member";
      let editUrl = "/member";
      let removeUrl = "/member";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        searchOnInit: true,
        rows: [
          [
            {
              name: "university",
              text: "สถานศึกษา",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/universities",
              },
              columnClass: "col-12 col-md-6",
            },
            {
              name: "team",
              text: "ทีม",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/teams",
              },
              columnClass: "col-12 col-md-6",
            },
          ],
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text,
              columnClass: "col-12 col-md-4",
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MENTOR],
        edit: [UserRole.ADMIN, UserRole.MENTOR],
        remove: [],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "fullName",
            text: "Name",
            style: {
              width: "160px",
            },
          },
          {
            name: "idCardNumber",
            text: "User",
            style: {
              width: "80px",
            },
          },
          {
            name: "tel",
            text: "เบอร์โทร",
            style: {
              width: "50px",
            },
          },
          {
            name: "university.text",
            text: "สถานศึกษา",
            style: {
              width: "230px",
            },
          },
          {
            name: "teamName",
            text: "ชื่อทีม",
            style: {
              width: "160px",
            },
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              text: "Reset password",
              columnClass: "text-right",
              type: InputType.resetPassword,
              extend: {
                url: "/Account/ResetPassword",
              },
              showIf: ({ model }) => {
                let result = false;

                let token = getToken();
                let condition1 = model.id ? true : false;
                let condition2 = token.username == Usr.Support;
                let condition3 = [UserRole.ADMIN, UserRole.MENTOR].includes(
                  token.role
                );

                let others = [condition2, condition3];

                if (condition1 && others.some((x) => x == true)) {
                  result = true;
                }

                return result;
              },
            },
          ],
          [
            {
              name: "namePrefix",
              text: "คำนำหน้าชื่อ",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/namePrefixes",
              },
              rule: {
                required: true,
              },
              columnClass: "col-8 col-md-3",
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              name: "firstNameThai",
              text: "ชื่อ (ภาษาไทย)",
              type: InputType.text,
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "lastNameThai",
              text: "นามสกุล (ภาษาไทย)",
              type: InputType.text,
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "firstNameEnglish",
              text: "ชื่อ (ภาษาอังกฤษ)",
              type: InputType.text,
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "lastNameEnglish",
              text: "นามสกุล (ภาษาอังกฤษ)",
              type: InputType.text,
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-3",
            },
          ],
          [
            {
              name: "nickName",
              text: "ชื่อเล่น",
              type: InputType.text,
              rule: {
                max: 20,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "birthDate",
              text: "วันเกิด(ตัวอย่าง 31/12/2530)",
              type: InputType.datePicker,
              rule: {},
              columnClass: "col-12 col-md-3",
            },
            {
              name: "email",
              text: "Email",
              type: InputType.text,
              rule: {
                required: true,
                email: true,
                max: 40,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "tel",
              text: "เบอร์โทรศัพท์",
              type: InputType.text,
              rule: {
                max: 11,
              },
              columnClass: "col-12 col-md-3",
            },
            {
              name: "lineId",
              text: "Line Id",
              type: InputType.text,
              rule: {
                max: 30,
              },
              columnClass: "col-12 col-md-3",
            },
          ],
          [
            {
              name: "university",
              text: "สถานศึกษา",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/universities",
              },
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-4",
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              name: "faculty",
              text: "คณะ",
              type: InputType.dropdownRelate,
              parent: "university",
              data: {
                url: "/dropdown/faculties?universityId=",
              },
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-4",
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              name: "major",
              text: "สาขา",
              type: InputType.dropdownRelate,
              parent: "faculty",
              data: {
                url: "/dropdown/majors?facultyId=",
              },
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-4",
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              type: "section",
              text: "Credential",
            },
          ],
          [
            {
              name: "idCardNumber",
              text: "เลขประจำตัวประชาชน",
              type: InputType.text,
              disabledWhenEdit: true,
              rule: {
                required: true,
                idCardNumber: true,
                min: 13,
                max: 13,
              },
              columnClass: "col-12 col-md-4",
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              type: "section",
              text: "รูปบัตรนักศึกษา",
            },
          ],
          [
            {
              name: "imageList",
              default: [],
              type: InputType.memberGallery,
            },
          ],
        ],
      };
    },
  },
};
</script>
